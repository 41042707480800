import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "./status.scss";
import { useNavigate } from "react-router-dom";
import successImage from "../images/success.png";
import failImage from "../images/fail.png";

function Status() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const myParam = queryParams.get();
  const allValues = {};
  queryParams.forEach((value, key) => {
    allValues[key] = value;
  });

  console.log("myParam", allValues);

  const navigate = useNavigate();
  return (
    <>
      <div className="content">
        <div className="wrapper-3">
          {allValues?.status === "true" && (
            <div className="wrapper-2">
              {/* <h1>Thank you</h1> */}
              <img
                src={successImage}
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <h2 style={{ margin: "10px 0" }}>Success</h2>
              <p>Transaction Success</p>
              <h1> &#8377;{allValues?.amount} </h1>
              <p className="date-payment">{allValues?.date}</p>
              <hr
                style={{ color: "black", width: "350px", margin: "15px 0 0 0" }}
              />
              <div className="paymentDetails">
                <ul style={{ listStyle: "none", width: "100%" }}>
                  <li className="listItem">
                    <div className="heading">PLAN</div>
                    <div className="value">{allValues?.plan}</div>
                  </li>
                  <li className="listItem">
                    <div className="heading">ORDER ID</div>
                    <div className="value">{allValues?.orderid}</div>
                  </li>
                  <li className="listItem">
                    <div className="heading">UPI Transaction Id</div>
                    <div className="value">{allValues?.txnid}</div>
                  </li>
                </ul>
              </div>
              <button onClick={() => navigate("/")} className="go-home">
                Done
              </button>
            </div>
          )}
          {allValues?.status === "false" && (
            <div className="wrapper-2">
              {/* <h1>Thank you</h1> */}
              <img
                src={failImage}
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <h2 style={{ margin: "10px 0" }}>Payment Failed</h2>
              <p>
                Unable to complete transaction. Please try another payment
                method
              </p>

              {/* <h1> &#8377;{allValues?.amount} </h1>
              <p className="date-payment">{allValues?.date}</p> */}
              {/* <hr
                style={{ color: "black", width: "350px", margin: "15px 0 0 0" }}
              /> */}

              <button onClick={() => navigate("/")} className="go-home-fail">
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Status;
