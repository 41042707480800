import * as actionTypes from '../actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL } from '../../config';

export const checkPhone = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkphone/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const checkbanknumber = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkbanknumber/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const checkemail = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkemail/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const checkgstin = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkgstin/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const checkrefercode = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkrefercode/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const checkPanNumber = async (payload) => {
	return await axios
		.post(`${API_URL}/subbroker/checkpannumber/`, payload)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log('error', err);
			return null;
		});
};

export const getPaymentDetails = async (payload) => {
	return axios
    .post(`${API_URL}/subbroker/giveinvoice/`, payload)
    .then((res) => {
      console.log("response ", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("error", err);
      return null;
    });
};
export const getInvoice = async (payload) => {
	return axios
    .post(`${API_URL}/etinvoice/`, payload)
    .then((res) => {
      console.log("response ", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("error", err);
      return null;
    });
};

export const createaffiliate = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		console.log('getting in');
		const token = localStorage.getItem('authToken');
		// /invoice/getInvoicedetails1/
		axios
      .post(`${API_URL}/subbroker/createbroker3/`, payload)
      .then((res) => {
        console.log("====", res);
        if (res?.data?.status === true) {
          dispatch({
            type: actionTypes.BROKER_REGISTER_SUCCESS,
          });
          toast.success(res?.data?.message || "Vendor register successfull", {
            autoClose: 1500,
          });
        } else {
          dispatch({
            type: actionTypes.BROKER_REGISTER_FAIL,
            payload: res.data?.message || "Failed to register",
          });
          toast.error(res?.data?.message || "Vendor register failed", {
            autoClose: 1500,
          });
        }
        resolve(res?.data);
      })
      .catch((e) => {
        reject(e);
        dispatch({
          type: actionTypes.BROKER_REGISTER_FAIL,
        });
        console.log("eeee", e);
        toast.error(e?.response?.data?.message || "Internal Server Error", {
          autoClose: 1500,
        });
      });
	});
};
