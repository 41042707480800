import { API_URL, PANEL_API_URL } from '../../config';
import * as actionTypes from '../actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { generatePopup } from '../../utils/popup';

export const sendOtp = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: actionTypes.OTP_SENT_INIT,
		});
		axios
			.post(`${API_URL}/auth/otp/`, payload)
			.then((res) => {
				if (res.status == 200) {
					dispatch({
						type: actionTypes.OTP_SENT_SUCCESS,
						payload: res.data,
					});
					resolve(res?.data);
				} else {
					dispatch({
						type: actionTypes.OTP_SENT_FAIL,
						payload: res.data?.message || 'Failed to sent otp',
					});
					console.log('res', res);
				}
			})
			.catch((e) => {
				dispatch({
					type: actionTypes.OTP_SENT_FAIL,
					payload: typeof e == 'string' ? e : 'Failed to otp',
				});
			});
	});
};

export const sendPaymentResponse = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/auth/sendmail/`, payload)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const checkPhoneNumber = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/auth/phonecheck/`, payload)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const sendMessage = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_URL}/auth/sendcontactmessage1/`, payload)
			.then((res) => {
				dispatch({
					type: actionTypes.MESSAGE_SENT_SUCCESS,
					payload: res.data?.message || 'Message sent successfully',
				});

				resolve(res);
			})
			.catch((error) => {
				dispatch({
					type: actionTypes.MESSAGE_SENT_FAILED,
					payload: typeof error === 'string' ? error : 'Failed to send message',
				});
				reject(error);
			});
	});
};

export const verifyOtp = (payload, navigate) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: actionTypes.OTP_VERIFY_INIT,
		});
		axios
			.post(`${API_URL}/auth/login/`, payload, {})
			.then((res) => {
				if (res.status === 200) {
					dispatch({
						type: actionTypes.OTP_VERIFY_SUCCESS,
						payload: res.data,
					});
					window.location.replace('/');

					resolve(res);
				} else {
					dispatch({
						type: actionTypes.OTP_VERIFY_FAIL,
						payload: res.data?.message || 'Failed to verify otp',
					});
				}
			})
			.catch((error) => {
				if (error.response.data.otp === 'False') {
					dispatch({
						type: actionTypes.OTP_VERIFY_FAIL,
						payload: typeof error == 'string' ? error : 'Failed to verify otp',
					});
					resolve(error);
					generatePopup('error', error?.response?.data?.message);
				} else {
					dispatch({
						type: actionTypes.OTP_VERIFY_FAIL,
						payload: typeof error == 'string' ? error : 'User not registered',
					});
					resolve(error);
					generatePopup('error', error?.response?.data?.message);
				}
			});
	});
};

export const authSignup = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: actionTypes.AUTH_SIGNUP_INIT,
		});
		const mode = localStorage.getItem('mode');
		axios
			.post(`${API_URL}/auth/register/`, payload)
			.then((res) => {
				console.log('=====rwes', res);
				if (res.status == 201) {
					dispatch({
						type: actionTypes.AUTH_SIGNUP_SUCCESS,
						payload: res.data,
					});
					generatePopup('success', 'User register succesfully.');
					resolve(res);
					localStorage.setItem('authToken', res?.data?.access);
					localStorage.setItem('phone', res?.data?.phone);
					window.location.replace(
						`${PANEL_API_URL}?token=${res?.data?.access}&mode=${mode}&phone=${res?.data?.phone}`
					);
				} else if (res?.data?.status === false) {
					generatePopup('error', res?.data?.message || 'Something went wrong');
				} else {
					dispatch({
						type: actionTypes.AUTH_SIGNUP_FAIL,
						payload: res.data?.message || 'Failed to signup user',
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 400) {
					// console.log("error.response :>> ", error.response);
					dispatch({
						type: actionTypes.AUTH_SIGNUP_FAIL,
						payload:
							typeof error == 'string' ? error : 'User already registered!',
					});
					resolve(error);
					if (error?.response?.data?.email) {
						generatePopup('error', error?.response?.data?.email[0]);
					} else if (error?.response?.data?.phone) {
						generatePopup('error', error?.response?.data?.phone[0]);
					}
				}
			});
	});
};

export const addStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.ADD_STRATEGY_INIT,
    });
    axios
      .post(`${API_URL}/auth/add_strategy/ `, payload)
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_STRATEGY_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else if (res?.data?.status === false) {
          generatePopup("error", res?.data?.message || "Something went wrong");
        } else {
          dispatch({
            type: actionTypes.ADD_STRATEGY_FAIL,
            payload: res.data?.message || "Failed to Send Details",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        dispatch({
          type: actionTypes.ADD_STRATEGY_FAIL,
          payload: typeof error == "string" ? error : "Failed to Send Details",
        });
        reject(error);
      });
  });
};

export const phonecheck = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: actionTypes.PHONE_CHECK_INIT,
		});
		axios
			.post(`${API_URL}/auth/phonecheck/`, payload)
			.then((res) => {
				if (res.status == 200) {
					dispatch({
						type: actionTypes.PHONE_CHECK_SUCCESS,
						payload: res.data,
					});
					resolve(res?.data);
				} else {
					dispatch({
						type: actionTypes.PHONE_CHECK_FAIL,
						payload: res.data?.message || 'Failed to sent otp',
					});
				}
			})
			.catch((e) => {
				dispatch({
					type: actionTypes.PHONE_CHECK_FAIL,
					payload: typeof e == 'string' ? e : 'Failed to otp',
				});
			});
	});
};
// export const getProfile = () => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     let token = localStorage.getItem("authToken");
//     dispatch({
//       type: actionTypes.AUTH_PROFILE_INIT,
//     });
//     axios
//       .get(`${API_URL}/auth/user/`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch({
//             type: actionTypes.AUTH_PROFILE_SUCCESS,
//             payload: res?.data,
//           });
//           resolve(res?.data);
//         } else {
//           dispatch({
//             type: actionTypes.AUTH_PROFILE_FAIL,
//             payload: res?.data?.message || "Failed to get profile!",
//           });
//         }
//       })
//       .catch((error) => {
//         if (error.response.status === 400) {
//           dispatch({
//             type: actionTypes.AUTH_PROFILE_FAIL,
//             payload: error?.response?.data?.message,
//           });
//         } else if (error?.response?.status === 401) {
//           generatePopup("error", "Token is invalid or expired.");
//           localStorage.clear();
//           window.location.replace("/");
//         }
//       });
//   });
// };
