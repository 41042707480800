import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import privacypolicy from '../images/privacy-policy.png';

export default function Privacypolicy() {
  return (
    <>
      <Box component={"section"} className="hero_section hero_section_small">
        <img src={privacypolicy} />

        <Box className="hero_content">
          <Box className="container">
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} xl={5}>
                <Typography component={"h2"}>
                  Privacy <Typography component={"span"}>Policy</Typography>
                </Typography>
                <Typography component={"p"}>
                  We at Dematade Algo Technology Solutions Private Limited value
                  your privacy. Regarding any information we might gather about
                  you on our website, we respect your right to privacy.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="container">
        <Box component={"section"}>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Refund Policy for{" "}
              <Typography component={"span"}>Subscription Services</Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              No Refunds Policy:
              <Typography component={"p"} className="common_text">
                We do not offer refunds or credits for subscription services
                once they have been purchased.
              </Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              Cancellation of Subscription:
              <Typography component={"p"} className="common_text">
                You may cancel your subscription at any time. However, please
                note that cancelling a subscription will not entitle you to a
                refund for any unused portion of the subscription period.
              </Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              Non-Transferable:
              <Typography component={"p"} className="common_text">
                Subscriptions are non-transferable. They cannot be transferred
                to another individual or account.
              </Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              Exceptions:
              <Typography component={"p"} className="common_text">
                In rare cases of technical issues or errors on our part that
                prevent you from accessing the subscription service as intended,
                we may consider refund requests on a case-by-case basis.
              </Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              Contact Us:
              <Typography component={"p"} className="common_text">
                If you have any questions or concerns about our refund policy or
                need assistance with your subscription, please contact our
                customer support team.
              </Typography>
            </Typography>
            <Typography component={"p"} className="common_text list">
              By purchasing a subscription, you agree to abide by this refund
              policy.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Terms And <Typography component={"span"}>Conditions</Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              You ("you" refers to the user or viewer of the Website) are
              consenting to be governed by the following terms and conditions
              and the Privacy Policy on the Website by accessing our website at
              https://dematadesolution.com ("Website"). These terms and
              conditions are subject to change at any moment. By using this
              website or any of its tools, whether directly or indirectly, you
              agree to abide by all of its terms and conditions and to accept
              any updated or revised versions that we may come up with. You
              should immediately leave this website if you disagree with any of
              the terms contained here.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Privacy <Typography component={"span"}>Policy </Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              At Dematade Algo Technology Solutions Private Limited, protecting
              the privacy of our users is of utmost importance to us. We have
              implemented robust measures to ensure the security and
              confidentiality of all personal information collected through our
              website. Our privacy policy outlines how we collect, use, store,
              and protect your data, as well as your rights regarding your
              personal information. We are dedicated to complying with relevant
              data protection laws and regulations to provide you with
              transparency and peace of mind when interacting with our platform.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Registration And{" "}
              <Typography component={"span"}>Termination</Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              By signing up for this service, you agree that Dematade Algo
              Technology Solutions Private Limited and its directors, employees,
              and associates (hereinafter collectively referred to as "Dematade
              Algo Technology Solutions Private Limited") reserve the right, in
              its sole discretion, to restrict your access to this website or
              any part of it without notice for any of the following reasons:
              (a) any unauthorized access or use by you; (b) if you attempt to
              assign or transfer (or do so) any rights granted to you;(c) in the
              event that you breach any of the other provisions of this User
              Agreement. Any legal or equitable rights or remedy to which
              Dematade Algo Technology Solutions Private Limited may be entitled
              are unaffected by the termination or cancellation of this
              Agreement. All rights granted to you under this User Agreement
              will expire upon termination of this agreement and will revert to
              Dematade Algo Technology Solutions Private Limited.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              License
            </Typography>
            <Typography component={"p"} className="common_text">
              You are granted a non-exclusive, royalty free, revocable license
              by Dematade Algo Technology Solutions Private Limited to:
            </Typography>
            <Typography component={"p"} className="common_text list">
              View this website and the content on this website on a computer or
              mobile device using a web browser.
            </Typography>
            <Typography component={"p"} className="common_text list">
              Save a copy of this website and its contents in the cache memory
              of your web browser.
            </Typography>
            <Typography component={"p"} className="common_text list">
              For your own private, non-commercial use, print pages from this
              website. You are not given any additional rights in respect to
              this website or the content on this website by Dematade Algo
              Technology Solutions Private Limited.
            </Typography>
            <Typography component={"p"} className="common_text">
              All other rights, in other words, are reserved. To be clear,
              without Dematade Algo Technology Solutions Private Limited' prior
              written consent, you may not modify, edit, change, convert,
              republish, disseminate, broadcast, display, or play in public this
              website or the content on this website (in any form or medium).
              data analysis It is not permitted to gather data from this website
              automatically or systematically. Protection of copyright Dematade
              Algo Technology Solutions Private Limited takes seriously the need
              to safeguard its copyright works. Dematade Algo Technology
              Solutions Private Limited may file legal action against you for an
              injunction to prevent you from using those materials and monetary
              damages if it comes to light that you have utilized our copyright
              materials in violation of the license stated above. Additionally,
              you could be required to cover our legal fees. Non enforceable
              clauses The validity of the remaining elements of this Website
              disclaimer will not be impacted if any provision is determined to
              be unlawful or unenforceable under applicable law who is the
              copyright owner Dematade Algo Technology Solutions Private Limited
              and its licensors owns the rights to this website and the content
              on it, including, but not limited to, the text, artwork, pictures,
              images, music, audio material, video material, and audio-visual
              material.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Security
            </Typography>
            <Typography component={"p"} className="common_text">
              Unauthorized use of this website and its systems is absolutely
              forbidden. This includes, but is not limited to, unauthorized
              access to online accounts, password misuse, and misuse of any
              other information. You are prohibited from using this website in
              any way that might harm, disable, overload, or impair it, or that
              could impede someone else from using and enjoying it.
            </Typography>
            <Typography component={"p"} className="common_text">
              You may not try to use hacking to gain unauthorized access to any
              of our sites or services, as well as the computer systems or
              networks that are linked to them. You acknowledge that using this
              website will not involve you in any actions that are prohibited by
              currently in force laws or regulations. Users are primarily
              responsible for keeping their login credentials and passwords
              private and for not disclosing such vital information to outside
              parties.
            </Typography>
            <Typography component={"p"} className="common_text">
              Dematade Algo Technology Solutions Private Limited is not liable
              for the loss of any such login information or for any act,
              omission, damages, claims, loss of personal information, etc.
              resulting from the use of any such leak of login information and
              passwords. Dematade Algo Technology Solutions Private Limited
              reserves the right to use any technology on its websites to gather
              information from visitors and to compile statistical data about
              how visitors use those websites, including information about the
              frequency of visits, the average duration of visits, and the pages
              that visitors view while on the website. For this reason, no
              personally identifiable data is gathered, and Dematade Algo
              Technology Solutions Private Limited does not keep track of the
              browsing habits of specific visitors.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Strategy Intellectual{" "}
              <Typography component={"span"}>Property</Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              The author of the plan will be the exclusive and exclusive owner
              of the intellectual property relating to the logic and
              configuration of the strategy. If Dematade Algo Technology
              Solutions Private Limited Inc. services are used to configure the
              strategy, and during that process, the creator discloses
              confidential information, Dematade Algo Technology Solutions
              Private Limited Inc. and its representatives are obligated to
              treat that information as trade secret information and not
              disclose it to any third party. Inc. takes no responsibility for
              it because it is a technological service provider that facilitates
              the interaction between traders and portfolio managers rather than
              a supplier of financial services in and of itself.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Strategy <Typography component={"span"}>Delays</Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              Dematade Algo Technology Solutions Private Limited reserves the
              right, in its sole discretion, to change, improve, or correct the
              information, materials, and descriptions on this website as well
              as to suspend and/or refuse access to this website for scheduled
              or unscheduled maintenance, upgrades, improvements, or
              corrections, without any obligation to provide notice or other
              notification requirements.
            </Typography>
            <Typography component={"p"} className="common_text">
              Dematade Algo Technology Solutions Private Limited reserves the
              right to terminate or alter any service mentioned or made
              available on this website, as well as any of its modules or
              components, whenever it sees appropriate. Dematade Algo Technology
              Solutions Private Limited (including it and its directors,
              employees, affiliates, agents, representatives or subcontractors)
              shall not be responsible for any loss or liability resulting,
              directly or indirectly, from delays or interruptions due to
              weather, strikes, walkouts, fire, riots, armed conflicts, acts of
              war, or other similar causes. While the website is being
              interrupted owing to one of these causes, Dematade Algo Technology
              Solutions Private Limited is not obligated to give you access to
              the website.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Liability
            </Typography>
            <Typography component={"p"} className="common_text">
              You acknowledge and agree that Dematade Algo Technology Solutions
              Private Limited, its affiliates, group companies, officers,
              directors, employees, agents, representatives, and subcontractors
              shall not, under any circumstances, be liable for any direct,
              indirect, special, consequential, or incidental damages or other
              damages of any kind arising out of the use or inability to use
              https://dematadesolution.com/ for any purpose, even if DeMatade is
              a party to the claim.
            </Typography>
            <Typography component={"p"} className="common_text">
              There is no contractual or other obligation that Dematade Algo
              Technology Solutions Private Limited, its affiliates, officers,
              directors, employees, or agents have to the user or any other
              party. This exclusion of liability covers, but is not limited to,
              the transmission of any viruses that could harm a user's computer
              equipment, the breakdown of mechanical or electronic devices or
              communication lines, telephone or other interconnect issues (such
              as when you can't reach your internet service provider),
              unauthorized access, theft, operator mistakes, strikes or other
              labor disputes, or any other unavoidable force majeure. Access to
              this website cannot and is not guaranteed to be continuous,
              uninterrupted, or secure by Dematade Algo Technology Solutions
              Private Limited.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Indemnification
            </Typography>
            <Typography component={"p"} className="common_text">
              You agree to defend, hold harmless, and indemnify Dematade Algo
              Technology Solutions Private Limited (along with its and their
              officers, directors, employees, affiliates, group companies,
              agents, representatives, and subcontractors) from any and all
              liabilities arising out of or connected with:
            </Typography>
            <Typography component={"p"} className="common_text list">
              Your access to and use of https://dematadesolution.com/;
            </Typography>
            <Typography component={"p"} className="common_text list">
              any violation of the terms and conditions by the user;
            </Typography>
            <Typography component={"p"} className="common_text list">
              any illegal or authorised acts by third parties in connection with
              the user's reception and use of the information.
            </Typography>
            <Typography component={"p"} className="common_text">
              Any provision found to be unenforceable shall be deemed severable
              and shall not impact the legality or enforceability of the other
              provisions. Only a written document that has been signed by
              Dematade Algo Technology Solutions Private Limited may change
              these terms. Dematade Algo Technology Solutions Private Limited
              shall be entitled to recover from you (and you agree to pay), in
              addition to all sums to which it is entitled or any other relief,
              at law or in equity, reasonable and necessary attorney's fees and
              any costs of any litigation if Dematade Algo Technology Solutions
              Private Limited takes action (by itself or through its associate
              companies) to enforce any of the provisions of this User
              Agreement, including collection of any amounts due hereunder.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title sec_title_small">
              Entire <Typography component={"span"}>Agreement</Typography>
            </Typography>
            <Typography component={"p"} className="common_text">
              Any prior written agreements between you and Dematade Algo
              Technology Solutions Private Limited are superseded by this User
              Agreement, which represents the parties' full understanding. You
              accept full responsibility for any and all gains and losses,
              financial, emotional or otherwise, experienced, lost or incurred
              by you by utilizing the material on https://dematadesolution.com/.
            </Typography>
            <Typography component={"p"} className="common_text">
              Dematade Algo Technology Solutions Private Limited does not
              provide investment advice, suggest the purchase or sale of any
              asset or investment by you or any other person, and does not
              guarantee the accuracy, completeness, or timeliness of the views,
              opinions, or recommendations represented in the Information. The
              Information is not meant to serve as tax, legal, or financial
              advice, all of which you should get from a competent adviser
              before making any of the kinds of investments the Information
              discusses. The information is not being offered for sale or
              purchase of securities by the information providers,
              https://dematadesolution.com/ or any other party.
            </Typography>
            <Typography component={"p"} className="common_text">
              The service is offered "as is," without any explicit or implied
              warranties of any kind, including, but not limited to, any
              warranties for the accuracy or timeliness of the information,
              data, services, or uninterrupted access supplied by or in
              connection with the service. In particular,
              https://dematadesolution.com/ expressly disclaims any and all
              warranties, including, but not limited to:
            </Typography>
            <Typography component={"p"} className="common_text list">
              any warranties regarding the accessibility, correctness,
              usefulness, or content of information, products, or services; and
            </Typography>
            <Typography component={"p"} className="common_text list">
              any title warranties, non-infringement warranties, merchantability
              warranties, or fitness for a particular purpose warranties.
            </Typography>
            <Typography component={"p"} className="common_text">
              This limitation of liability applies to any damages or injuries
              brought on by any breach of contract, tort (including negligence),
              wrongful act, interruption, deletion, defect, delay in operation
              or transmission, computer virus, communication line failure, theft
              or destruction, or unauthorized access to, alteration of, or use
              of record. You or any other third party shall not hold
              https://dematadesolution.com/ or any of its employees, agents,
              successors, assigns, affiliates, group companies, or content or
              service providers responsible for any direct, indirect,
              incidental, special, or consequential damages resulting from the
              use of the service or from the inability to access the service or
              from any failure to comply with any warranty.
            </Typography>
            <Typography component={"p"} className="common_text">
              The following restriction may not apply to you because some
              nations do not permit the exclusion or limitation of
              responsibility for consequential or incidental damages. In such
              nations, the responsibility of (https://dematadesolution.com/),
              its employees, agents, successors, assigns, affiliates, group
              businesses, and content suppliers is restricted to the sum
              permitted by the relevant legislation. Additionally, you
              acknowledge that none of the services are refundable and that you
              should carefully examine if our services can satisfy your demands.
            </Typography>
          </Box>

          {/* <Box className='mrt-20'>
            <Typography component={'h3'} className='sec_title sec_title_small'>Disclaimer</Typography>
            <Typography component={'p'} className='common_text'>The material provided is solely for educational purposes. Staff at Dematade Algo Technology Solutions Private Limited do not recommend, advocate, or solicit the purchase, sale, or holding of any investment, the use of any investment selection methodology, the use of any specific financial tools or trading platform, or the use of any specific financial planner, advisor, or broker dealer. Remember that trading and investing are always fraught with danger. Any money you put into trading and investing might be lost entirely and at any time. Any liability for any trading or investment activity you engage in is solely yours. The demonstrations of trading procedures offered (including, but not limited to, selecting an investment and placing a transaction) do not indicate or predict that any account will or is likely to earn profits or losses similar to those mentioned. Future outcomes may differ significantly from the options offered in training. Past results do not guarantee future outcomes.</Typography>
          </Box> */}
        </Box>
      </Box>
    </>
  );
}
