import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Logo from "../images/logo.png";
import logomob from "../images/logo-mob.png";
import lighticon from "../images/light-icon.png";
import { NavLink } from "react-router-dom";

export default function Header(props) {
  return (
    <>
      <Box component={"nav"} className={`navigation ${props.sticky}`}>
        <Box className="container">
          <Box className={"nav"}>
            <NavLink component={"a"} to={"/"} className="logo">
              <img src={Logo} />
            </NavLink>

            <Box className={`nav_menu ${props.sidebar}`}>
              <Box component={"ul"} className="nav_buttons_mob closeSideBar">
                <Box
                  component={"li"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <img src={Logo} style={{ height: "45px", width: "120px" }} />
                  <Button
                    className="btn-ta square filled"
                    onClick={props.sidebraHandler}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 12L7 7M12 12L17 17M12 12L17 7M12 12L7 17"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Box>
              </Box>
              <Box component={"ul"} className="nav_menu_ul">
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    Home
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/affiliate"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    AFFILIATE
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/strategy"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    STRATEGY
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/about"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    ABOUT US
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/contact"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    CONTACT
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/signin"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    Sign in
                  </NavLink>
                </Box>
                <Box component={"li"}>
                  <NavLink
                    component={"a"}
                    to={"/signup"}
                    className="nav_link"
                    onClick={props.sidebraHandler}
                  >
                    Sign up
                  </NavLink>
                </Box>
              </Box>
              <Box component={"ul"} className="nav_buttons_mob bottom">
                <Box component={"li"}>
                  <FormControlLabel
                    className="lightDark_mode"
                    value="Light / Dark Mode"
                    control={<Switch color="primary" />}
                    label="Light / Dark Mode"
                    labelPlacement="start"
                    onClick={props.modeChange}
                  />
                </Box>
              </Box>
            </Box>

            <Box component={"ul"} className="nav_buttons">
              <Box
                component={"li"}
                className="humburgar"
                onClick={props.sidebraHandler}
              >
                <Button className="btn-ta square filled">
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M4 18h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h8c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm17.3 7.88L17.42 12l2.88-2.88c.39-.39.39-1.02 0-1.41a.9959.9959 0 0 0-1.41 0L15.3 11.3c-.39.39-.39 1.02 0 1.41l3.59 3.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z"></path>
                  </svg>
                </Button>
              </Box>
              <Box component={"li"}>
                <Button
                  className="btn-ta square filled"
                  onClick={props.modeChange}
                >
                  <img src={props.lighticon} />
                </Button>
              </Box>
              <Box component={"li"}>
                <NavLink
                  component={"a"}
                  to={"/signin"}
                  className="btn-ta filled"
                >
                  Sign In
                </NavLink>
              </Box>
              <Box component={"li"}>
                <NavLink
                  component={"a"}
                  to={"/signup"}
                  className="btn-ta filled"
                >
                  Sign Up
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
