import React, { useState, useEffect } from "react";
import light from "../images/light-icon.png";
import dark from "../images/dark-icon.png";
import Contactbox from "../Contactbox/Contactbox";
import Status from "../Pages/Status";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import Header from "../Header/Header";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Affiliate from "../Pages/Affiliate";
import Strategy from "../Pages/Strategy";
import Contact from "../Pages/Contact";
import Faq from "../Pages/Faq";
import Privacypolicy from "../Pages/Privacypolicy";
import Descliamer from "../Pages/Desclaimer";
import Footer from "../Footer/Footer";

import Signin from "../Signin/Signin";
import Signup from "../Signin/Signup";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function Mainlayout() {
  const [sticky, setSticky] = useState("");
  const [lighticon, setlighticon] = useState(light);
  const [sidebar, setsidebar] = useState(" ");
  const theme = document.querySelector("html");
  const [showTopBtn, setShowTopBtn] = useState("-100%");
  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function () {
  //     var s1 = document.createElement("script"),
  //       s0 = document.getElementsByTagName("script")[0];
  //     s1.async = true;
  //     s1.src = "https://embed.tawk.to/65d880fe8d261e1b5f646696/1hnarfp6v";
  //     s1.charset = "UTF-8";
  //     s1.setAttribute("crossorigin", "*");
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // }, []);

  // Sticky Header
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 50 ? "sticky" : "";
    setSticky(stickyClass);
  };

  // Light & Dark mode
  useEffect(() => {
    let mode = localStorage.getItem("mode");

    if (!mode) {
      theme.setAttribute("data-theme", "light");
      localStorage.setItem("mode", "light");
    } else if (mode === "light") {
      theme.setAttribute("data-theme", "light");
      localStorage.setItem("mode", "light");
    } else {
      theme.setAttribute("data-theme", "dark");
      localStorage.setItem("mode", "light");
    }
  }, []);

  const modeChange = (e) => {
    let mode = localStorage.getItem("mode");

    if (mode === "light") {
      theme.setAttribute("data-theme", "dark");
      localStorage.setItem("mode", "dark");
      setlighticon(light);
    } else {
      theme.setAttribute("data-theme", "light");
      localStorage.setItem("mode", "light");
      setlighticon(dark);
    }
  };

  // Sidebar Handler
  const sidebraHandler = () => {
    if (sidebar === " ") {
      setsidebar("open");
    } else {
      setsidebar(" ");
    }
  };

  // Help Canter & Go to Top show
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn("10rem");
      } else {
        setShowTopBtn("-100%");
      }
    });
  }, []);

  // Go to Top
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Load Offering
  setTimeout(() => {
    // setOpen(true);
  }, 1000);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Header
        modeChange={modeChange}
        sidebraHandler={sidebraHandler}
        sidebar={sidebar}
        lighticon={lighticon}
        sticky={sticky}
      />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/affiliate" element={<Affiliate />}></Route>
        <Route path="/strategy" element={<Strategy />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
        <Route path="/descliamer" element={<Descliamer />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/signin" element={<Signin />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/status" element={<Status />}></Route>
      </Routes>
      <Footer />
      <Contactbox showTopBtn={showTopBtn} goToTop={goToTop} />

      {/* offer Modal */}
      <Dialog open={open} onClose={handleClose} className="offer_modal">
        <DialogContent dividers>
          <img
            src="https://dummyimage.com/600x400/000/fff"
            height="100%"
            width="100%"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
