import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Marquee from 'react-fast-marquee';
import AlgorithmDevelopment from '../images/Algorithm_Development.png';
import CompleteAutomation from '../images/Complete_automation.png';
import MultipleExecution from '../images/Multiple_execution.png';
import MultipleSegment from '../images/Multiple_segment.png';
import StrategyBuilder from '../images/Strategy_Builder.png';
import StrategyMarketplace from '../images/Strategy_marketplace.png';
import about from '../images/about.png';
import OneStopSolution from '../images/one_stop_solution.png';
import ourmission from '../images/our-mission.png';
import ourvision from '../images/our-vision.png';

export default function About() {
	console.log('test');
	const deliverData = [
		{
			title: 'One-Stop Solution: ',
			text: 'Trade Arth serves as a one-stop destination for traders, providing a complete suite of tools and solutions to facilitate their trading journey. Whether traders are looking for pre-built strategies, algorithm development, or automation options, we have them covered.',
			icon: OneStopSolution,
		},
		{
			title: 'Algorithm Development',
			text: 'Our platform allows traders to develop their custom trading algorithms using our advanced algorithm development tools. Traders can create algorithms based on their unique trading strategies and preferences.',
			icon: AlgorithmDevelopment,
		},

		{
			title: 'Strategy Builder',
			text: 'We offer a user-friendly strategy builder that enables traders to construct their trading strategies without the need for extensive programming knowledge. Traders can set specific rules and conditions to automate their trading decisions.',
			icon: StrategyBuilder,
		},

		{
			title: 'Multiple Execution',
			text: 'Traders can execute multiple trades simultaneously using our platform, allowing for efficient management of their portfolios and trading activities across various financial instruments.',
			icon: MultipleExecution,
		},

		{
			title: 'Multiple Segment',
			text: 'Multiple segment algorithmic trading refers to the use of algorithmic trading strategies that operate across multiple financial markets or segments. Segments in this context refer to different markets or asset classes .',
			icon: MultipleSegment,
		},
		{
			title: 'Completely Automation',
			text: 'Our platform supports complete automation, enabling traders to deploy their algorithms and strategies to execute trades automatically. This eliminates the need for manual intervention and ensures timely execution based on predefined rules.',
			icon: CompleteAutomation,
		},
		{
			title: 'Strategy Marketplace',
			text: 'We offer a strategy marketplace where traders can access and explore a variety of pre-built trading strategies. Traders can choose from a diverse selection of strategies developed by experts and fellow traders to suit their trading objectives',
			icon: StrategyMarketplace,
		},
	];

	return (
		<>
			<Box component={'section'} className='hero_section'>
				<img src={about} />

				<Box className='hero_content'>
					<Box className='container'>
						<Grid container spacing={0}>
							<Grid item xs={12} md={6} xl={5}>
								<Typography component={'h2'}>
									About <Typography component={'span'}>Us</Typography>
								</Typography>
								<Typography component={'p'}>
									At TradeArth, we are dedicated to revolutionizing the way you
									trade in financial markets.Our mission is to empower traders
									with cutting-edge algorithmic trading software that unlocks
									the full potential of automated trading strategies.Backed by
									advanced technology and years of expertise, we strive to
									provide you with a competitive edge in the fast-paced world of
									trading.
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>

			<Box component={'section'} className='deliver_section'>
				<Box className='container'>
					<Typography component={'h3'} className='sec_title'>
						What <Typography component={'span'}>We Do ?</Typography>
					</Typography>
					<Typography component={'p'} className='common_text'>
						At Trade Arth, our core focus is to provide traders with the tools
						and support they need to succeed in the dynamic world of financial
						markets. Here's what we do:
					</Typography>

					<Marquee
						autoFill
						pauseOnHover
						pauseOnClick
						speed={80}
						className='autoScroll'
					>
						{deliverData.map((items, inex) => (
							<Box
								className='devliver_box border-gradient'
								key={'deliver_item' + inex}
							>
								<Box className='devliver_box_header'>
									{/* <Box className='deliver_icon'> */}
									<img className='deliver_icon' src={items.icon} alt='' />
									{/* {items.icon} */}
									{/* </Box> */}
									<Typography component={'h4'}>{items.title}</Typography>
								</Box>
								<Box className='devliver_box_body'>
									<Typography component={'p'}>{items.text}</Typography>
								</Box>
							</Box>
						))}
					</Marquee>
				</Box>
			</Box>

			<Box component={'section'} className='wat_section'>
				<Box className='container'>
					<Typography component={'h3'} className='sec_title'></Typography>
					<Grid
						container
						spacing={2}
						className='mr-top'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: { xs: 'center', lg: 'space-between' },
						}}
					>
						<Grid
							item
							sx={{
								order: { xs: 2, lg: 1 },
							}}
							xs={12}
							sm={10}
							md={8}
							lg={7}
						>
							<Typography component={'h3'} className='sec_title notAfter'>
								Our <Typography component={'span'}>Vision</Typography>
							</Typography>
							<Typography component={'p'} className='desc text-grey'>
								Our vision at Trade Arth is centered around increasing the
								simplicity and accessibility of algorithmic trading for Indian
								traders and investors. We believe that the use of algorithms in
								trading and financial management presents both fascinating
								opportunities and challenges. To fully utilize the potential of
								algorithms, a unique skill set is required, involving
								conceiving, developing, and conducting quantitative research.We
								are committed to breaking down the barriers that may hinder
								traders from embracing algorithmic trading. Our mission is to
								make algorithmic trading more accessible to every retail trader
								in India. We want to empower traders with the capabilities of
								algorithms and automation, enabling them to enhance the
								efficiency and profitability of their trading endeavors.
							</Typography>
						</Grid>
						<Grid
							item
							sx={{
								order: { xs: 1, lg: 2 },
							}}
							xs={12}
							sm={10}
							md={8}
							lg={4}
						>
							<Box sx={{ padding: { xs: 0, md: 2 } }}>
								<img src={ourvision} height='100%' width='100%' />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Box component={'section'} className='wat_section'>
				<Box className='container'>
					<Typography component={'h3'} className='sec_title'></Typography>
					<Grid
						container
						spacing={2}
						className='mr-top'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: { xs: 'center', lg: 'space-between' },
						}}
					>
						<Grid item xs={12} sm={10} md={8} lg={4}>
							<Box sx={{ padding: { xs: 0, md: 2 } }}>
								<img src={ourmission} height='100%' width='100%' />
							</Box>
						</Grid>

						<Grid item xs={12} sm={10} md={8} lg={7}>
							<Typography component={'h3'} className='sec_title notAfter'>
								Our <Typography component={'span'}>Mission</Typography>
							</Typography>
							<Typography component={'p'} className='desc text-grey list'>
								Simplicity: We strive to simplify the complexities of
								algorithmic trading. By providing user-friendly tools, intuitive
								interfaces, and educational resources.
							</Typography>
							<Typography component={'p'} className='desc text-grey list'>
								Accessibility: Our platform is designed to be accessible to
								every trader in India.
							</Typography>
							<Typography component={'p'} className='desc text-grey list'>
								Empowerment: Our ultimate goal is to empower traders and
								investors with the tools and knowledge they need to succeed.
							</Typography>
							<Typography component={'p'} className='desc text-grey list'>
								Overall, at Trade Arth, our mission is to empower traders with
								reliable, efficient, and innovative tools that help them thrive
								and succeed in the fast-paced world of financial markets. We
								strive to be a trusted affiliate for traders, supporting them on
								their trading journey and helping them achieve their financial
								goals.
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}
