export const validateAffiliate = (fields) => {
	console.log("fields in validate",fields)
	let isValid = {};

	if (!fields.firstname) {
		isValid.firstname = 'Enter first name';
	} else if (!/^[A-Za-z]+$/.test(fields.firstname)) {
		isValid.firstname = 'First name only contain letters';
	} else if (fields.firstname.length < 3 || fields.firstname.length > 10) {
		isValid.firstname = 'First name: 3-10 characters';
	}

	if (!fields.lastname) {
		isValid.lastname = 'Enter last name';
	} else if (!/^[A-Za-z]+$/.test(fields.lastname)) {
		isValid.lastname = 'Last name only contain letters';
	} else if (fields.lastname.length < 3 || fields.lastname.length > 10) {
		isValid.lastname = 'Last name: 3-10 characters';
	}

	if (!fields.email) {
		isValid.email = 'Please enter email';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields.email)) {
		isValid.email = 'Invalid email address';
	} else if (!(fields.email.length >= 15) || !(fields.email.length <= 40)) {
		isValid.email = 'Email length should be 15 to 40';
	} else if (!fields.email.endsWith('.com') && !fields.email.endsWith('.in')) {
		isValid.email = 'Enter valid email';
	}

	if (!fields.phone) {
		isValid.phone = 'Phone Number is required.';
	} else if (!/^[0-9]+$/.test(fields?.phone?.replace(/-/g, ''))) {
		isValid.phone = 'Phone Number should be numeric value.';
	} else if (fields?.phone?.replace(/-/g, '').length < 10) {
		isValid.phone = 'Enter valid phone number ';
	}

	if (!fields.bankname) {
		isValid.bankname = 'Bankname is required.';
	} else if (!/^[A-Za-z ]+$/.test(fields.bankname)) {
		isValid.bankname = 'Bankname should be numeric value.';
	} else if (fields.bankname.length > 50 || fields.bankname.length < 3) {
		isValid.bankname = 'Bankname length should be 3-50';
	}


	if (fields.gstin && fields?.gstin?.length !== 15) {
		isValid.gstin = 'GSTIN length should be 15';
	}

	if (!fields.ifsccode) {
		isValid.ifsccode = 'IFSC code is required.';
	} else if (!/^[A-Za-z0-9]+$/.test(fields.ifsccode)) {
		isValid.ifsccode = 'IFSC code should be numeric value.';
	} else if (fields.ifsccode.length > 11) {
		isValid.ifsccode = 'IFSC code length should be 11';
	}

	if (!fields.banknumber) {
		isValid.banknumber = 'Account Number is required.';
	} else if (!/^[0-9]+$/.test(fields.banknumber)) {
		isValid.banknumber = 'Account Number should be numeric value.';
	} else if (fields.banknumber.length > 20) {
		isValid.banknumber = 'Account Number length should be 20';
	}

	if (!fields.branch_name) {
		isValid.branch_name = 'Branch name is required.';
	}
	else if(!/^[A-Za-z0-9\s]+$/.test(fields.branch_name))
	{
		isValid.branch_name = "Branch name can only contain letters & numbers."
	}
	else if(fields.branch_name.length > 50)
	{
		isValid.branch_name = 'Branch name length should be of maximum 50 characters.'
	} else {
		isValid.branch_name = '';
	}

	if (!fields.bank_city) {
		isValid.bank_city = 'Bank city is required.';
	}
	if (!fields.dob) {
		isValid.dob = 'DOB is required.';
	} else if(!/^\d{4}-\d{2}-\d{2}$/.test(fields.dob))
	{
		isValid.dob = 'Please enter valid date of birth.'
	}
	 else {
		const dobDate = new Date(fields.dob);

		const currentDate = new Date();

		let age = currentDate.getFullYear() - dobDate.getFullYear();

		if (
			currentDate.getMonth() < dobDate.getMonth() ||
			(currentDate.getMonth() === dobDate.getMonth() &&
				currentDate.getDate() < dobDate.getDate())
		) {
			age--;
		}

		if (age < 18) {
			isValid.dob = 'You must be at least 18 years old.';
		}
	}

	if (!fields.pan_number) {
		isValid.pan_number = 'PAN number is required.';
	} else if (!/^[A-Za-z0-9]+$/.test(fields.pan_number)) {
		isValid.pan_number = 'PAN number should be alphanumeric value.';
	} else if (fields.pan_number.length !== 10) {
		isValid.pan_number = 'PAN number length should be 10';
	}

  if (!fields.mrp) {
    isValid.mrp = "MRP is required.";
  } else if (
    // fields.mrp > 5000 ||
    fields.mrp <= 0
  ) {
    isValid.mrp = "MRP should be greater than 0";
  }

	if (!fields.refercode) {
		isValid.refercode = 'Refer code is required.';
	}
	else if(fields?.refercode?.length <5 || fields?.refercode?.length>10)
	{
		isValid.refercode = 'Refer Code length should be of min 5 and max 10 characters.';
	}

	if (!fields?.state || fields?.state === '') {
		isValid.state = 'State is required.';
	}

	return isValid;
};
