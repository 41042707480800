export const BROKER_REGISTER_SUCCESS = "BROKER_REGISTER_SUCCESS";
export const BROKER_REGISTER_FAIL = "BROKER_REGISTER_FAIL";

export const PHONE_CHECK_INIT = "PHONE_CHECK_INIT";
export const PHONE_CHECK_SUCCESS = "PHONE_CHECK_SUCCESS";
export const PHONE_CHECK_FAIL = "PHONE_CHECK_FAIL";

export const OTP_SENT_INIT = "OTP_SENT_INIT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const OTP_SENT_FAIL = "OTP_SENT_FAIL";

export const OTP_VERIFY_INIT = "OTP_VERIFY_INIT";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = "OTP_VERIFY_FAIL";

export const AUTH_SIGNUP_INIT = "AUTH_SIGNUP_INIT";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";
export const AUTH_SIGNUP_FAIL = "AUTH_SIGNUP_FAIL";

export const ADD_STRATEGY_INIT = "ADD_STRATEGY_INIT";
export const ADD_STRATEGY_SUCCESS = "ADD_STRATEGY_SUCCESS";
export const ADD_STRATEGY_FAIL = "ADD_STRATEGY_FAIL";

export const MESSAGE_SENT_FAILED = "MESSAGE_SENT_FAILED";
export const MESSAGE_SENT_SUCCESS = "MESSAGE_SENT_SUCCESS";
