import React, { useEffect, useCallback, useState } from "react";
import { debounce, cloneDeep } from "lodash";
import {
  Box,
  Typography,
  Button,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Dialog,
  DialogContent,
  Tooltip,
  TextField,
  ListItemText,
} from "@mui/material";
import "./affiliate.scss";
import affiliatepng from "../images/affiliate.png";
import logo from "../images/dematade_logo.svg";
import { useDispatch } from "react-redux";
import { checkPhone, checkemail } from "../redux/actions/affiliateActions";
import { validateStrategy } from "../validation/strategy ";
import { toast } from "react-toastify";
import { addStrategy } from "../redux/actions/authActions";
import { generatePopup } from "../utils/popup";

export default function Strategy() {
  const [scroll, setScroll] = useState("paper");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPartnerSuccessfullyCreated, setIsPartnerSuccessfullyCreated] =
    useState(false);
  const initialStrategyData = {
    strategyname: "",
    strategycategory: "INDEX OPTION BUYING",
    segment: ["NIFTY"],
    timeframe: "3 MIN",
    tradeperday: "1",
    indicators: "",
    entryconditions: "",
    exitconditions: "",
    stoploss: "",
    target: "",
    additionalnotes: "",
    ownername: "",
    whatsappnumber: "",
    email: "",
  };
  const [strategyData, setStrategyData] = useState(initialStrategyData);
  // const keys = {
  //   strategyname: "strategyname",
  //   strategycategory: "strategycategory",
  //   segment: "segment",
  //   timeframe: "timeframe",
  //   tradeperday: "tradeperday",
  //   indicators: "indicators",
  //   entryconditions: "entryconditions",
  //   exitconditions: "exitconditions",
  //   stoploss: "stoploss",
  //   target: "target",
  //   additionalnotes: "additionalnotes",
  //   ownername: "ownername",
  //   whatsappnumber: "whatsappnumber",
  //   emailid: "email",
  // };
  const strategyCategoryOption = [
    "Index Option Buying",
    "Index Option Selling",
    "Index Future",
    "NSE Cash",
    "MCX Future",
    "MCX Option",
    "STOCK Future",
  ];
  const segmentOption = [
    "NIFTY",
    "BANKNIFTY",
    "FINNIFTY",
    "SENSEX",
    "MIDCPNIFTY",
  ];
  const timeframeOption = [
    "3 MIN",
    "5 MIN",
    "15 MIN",
    "30 MIN",
    "1 HOUR",
    "DAILY",
    "MONTHLY",
  ];
  const tradeperdayOption = [1, 2, 3, 4, 5, 6, 7, 8, 10];
  const [fielderror, setFieldError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const dispatch = useDispatch();

  const isErrors = (errors) => {
    const keys = Object.keys(errors);
    let isErrorBool = false;

    if (keys?.length === 0) {
      isErrorBool = false;
    } else {
      for (let i = 0; i < keys.length; i++) {
        const val = errors[keys[i]];
        if (val?.trim().length !== 0) {
          isErrorBool = true;
          break;
        } else {
          isErrorBool = false;
        }
      }
    }

    return isErrorBool;
  };

  useEffect(() => {
    if (isErrors(fielderror)) {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
  }, [fielderror, isSubmit]);

  const handleFormSubmit = async () => {
    const error = validateStrategy(strategyData);
    setFieldError(error);

    const hasErrors = Object.keys(error).length > 0;

    if (hasErrors) {
      generatePopup("error", "Please fill out required fields");
      return;
    }
    try {
      setIsPartnerSuccessfullyCreated(false);
      setIsLoading(true);
      const phone = 91 + strategyData?.whatsappnumber?.replace(/-/g, "");

      const newStrategyData = {
        ...strategyData,
        whatsappnumber: phone,
      };

      await dispatch(addStrategy(newStrategyData))
        .then((data) => {
          if (data?.status === 200) {
            toast.success(
              "Thank you for your request. Our team will get back to you soon.",
              {
                autoClose: 2000,
              }
            );
            setIsPaymentLoading(false);
            setTimeout(() => {
              setFieldError({});
              setStrategyData(initialStrategyData);
            }, 0);
            setIsSubmit(false);
            setIsPartnerSuccessfullyCreated(true);
          } else {
            setIsPaymentLoading(false);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("error in affiliate creating", err);
          setIsPaymentLoading(false);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("error :>> ", error);
      setIsPaymentLoading(false);
      setIsLoading(false);
    }
  };

  // Dropdown
  const strategyCategoryType = (event) => {
    const value = event.target.value.toUpperCase();
    setStrategyData((prev) => ({
      ...prev,
      strategycategory: value,
    }));
    setFieldError((prev) => {
      const newFieldError = { ...prev };
      delete newFieldError.strategycategory;
      return newFieldError;
    });
  };
  const timeframeDrp = (event) => {
    setStrategyData((prev) => ({
      ...prev,
      timeframe: event.target.value?.toUpperCase(),
    }));
    setFieldError((prev) => {
      const newFieldError = { ...prev };
      delete newFieldError.timeframe;
      return newFieldError;
    });
  };
  const tradeperdayDrp = (event) => {
    setStrategyData((prev) => ({
      ...prev,
      tradeperday: event.target.value,
    }));
    setFieldError((prev) => {
      const newFieldError = { ...prev };
      delete newFieldError.tradeperday;
      return newFieldError;
    });
  };
  const handleSegmentChange = (event) => {
    const { value } = event.target;

    const newSelectedValues =
      typeof value === "string" ? value.split(",") : value;
    setSelectedValues(newSelectedValues);

    setStrategyData((prev) => ({
      ...prev,
      segment: newSelectedValues,
    }));
    setFieldError((prev) => {
      const newFieldError = { ...prev };
      if (newSelectedValues.length > 0) {
        delete newFieldError.segment;
      }
      return newFieldError;
    });
  };
  const handleStrategyName = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.strategyname = "Enter Strategy Name";
    } else {
      fielderror.strategyname = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      strategyname: value,
    }));
  };
  const handleIndicators = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.indicators = "Enter Indicators";
    } else if (value.length >= 100) {
      fielderror.indicators = "Indicators cannot exceed 100 characters";
    } else {
      fielderror.indicators = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      indicators: value,
    }));
  };
  const handleEntryConditions = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.entryconditions = "Enter Entry Conditions";
    } else if (value.length <= 10) {
      fielderror.entryconditions =
        "Entry Conditions must be at least 10 characters";
    } else if (value.length >= 200) {
      fielderror.entryconditions =
        "Entry Conditions cannot exceed 200 characters";
    } else {
      fielderror.entryconditions = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      entryconditions: value,
    }));
  };
  const handleExitConditions = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.exitconditions = "Enter Entry Conditions";
    } else if (value.length <= 10) {
      fielderror.exitconditions =
        "Entry Conditions must be at least 10 characters";
    } else if (value.length >= 200) {
      fielderror.exitconditions =
        "Entry Conditions cannot exceed 200 characters";
    } else {
      fielderror.exitconditions = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      exitconditions: value,
    }));
  };
  const handleStopLoss = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.stoploss = "Enter Stop Loss";
    } else {
      fielderror.stoploss = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      stoploss: value,
    }));
  };
  const handleTarget = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.target = "Enter Target";
    } else {
      fielderror.target = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      target: value,
    }));
  };
  const handleAdditionalNotes = (e) => {
    const { value } = e.target;
    if (value) {
      setStrategyData((prev) => ({
        ...prev,
        additionalnotes: value,
      }));
    }
  };
  const handleStrategyOwnerName = (e) => {
    const { value } = e.target;
    if (!value) {
      fielderror.ownername = "Enter Strategy Owner Name";
    } else {
      fielderror.ownername = "";
    }
    setStrategyData((prev) => ({
      ...prev,
      ownername: value,
    }));
  };
  const handleNumber = async (event) => {
    const { value } = event.target;

    const formattedValue = value.replace(/\D/g, "");
    let formattedNumber = formattedValue;

    if (formattedValue.length > 5) {
      formattedNumber =
        formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (event.key === "Backspace") {
      // Handle backspace key
      if (formattedValue.length === 6 && formattedValue.charAt(5) === "-") {
        // Remove hyphen when deleting the 6th character
        formattedNumber = formattedValue.slice(0, 5);
      }
      if (formattedValue.length === 5) {
        // Remove last character when deleting the 5th character
        formattedNumber = formattedValue.slice(0, 4);
      }
    }

    if (formattedValue.length <= 10) {
      setStrategyData((prev) => ({
        ...prev,
        whatsappnumber: formattedNumber,
      }));
    }

    if (!formattedNumber) {
      fielderror.whatsappnumber = "Mobile is required.";
    } else if (formattedNumber.length < 10) {
      fielderror.whatsappnumber = "Enter valid Mobile number ";
    } else {
      fielderror.whatsappnumber = "";
      debouncePhone("91" + formattedNumber);
    }
  };

  const submitTooltip = (
    <Typography sx={{ fontSize: "1.4rem" }}>
      Please Enter All Required fields.
    </Typography>
  );

  const debounceDuration = 500;

  const debouncePhone = useCallback(
    debounce(async (value) => {
      let newPhone = value.replace(/-/g, "");
      if (!newPhone.includes("91")) {
        newPhone = 91 + newPhone;
      }
      const res = await checkPhone({ phone: newPhone });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.whatsappnumber = "Mobile number is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.whatsappnumber;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  const debounceEmail = useCallback(
    debounce(async (value) => {
      const res = await checkemail({ email: value });
      if (res?.data?.status !== true) {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          newFieldError.email = "Email is already registered";
          return newFieldError;
        });
      } else {
        setFieldError((prev) => {
          const newFieldError = cloneDeep(prev);
          delete newFieldError.email;
          return newFieldError;
        });
      }
    }, debounceDuration),
    [fielderror]
  );

  return (
    <>
      {isPaymentLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: 999999999999999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span class="loader"></span>
        </Box>
      ) : (
        <>
          <Box
            component={"section"}
            className="hero_section hero_section_small"
          >
            <img src={affiliatepng} />
          </Box>
          <Box component={"section"}>
            <Box className="container">
              <Typography component={"h3"} className="sec_title">
                Create <Typography component={"span"}>Strategy</Typography>
              </Typography>

              <Box className="input_form border-gradient">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    flexWrap: "wrap",
                  }}
                >
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Strategy Owner Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Strategy Owner Name"
                        value={
                          strategyData?.ownername ? strategyData?.ownername : ""
                        }
                        onChange={(e) => handleStrategyOwnerName(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.ownername}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        WhatsApp Contact Number
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputBase
                          type="text"
                          placeholder="Enter WhatsApp Number"
                          value={strategyData?.whatsappnumber}
                          className="inputFiled"
                          id="whatsappnumber"
                          name="number"
                          onChange={handleNumber}
                        />
                      </Box>
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.whatsappnumber}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Email ID
                      </Typography>
                      <InputBase
                        type="email"
                        placeholder="Enter Email ID"
                        value={strategyData?.email ? strategyData?.email : ""}
                        onChange={async (e) => {
                          if (!e.target.value) {
                            fielderror.email = "Please enter email";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              e.target.value
                            )
                          ) {
                            fielderror.email = "Invalid email address";
                          } else if (
                            !(e.target.value.length >= 10) ||
                            !(e.target.value.length <= 40)
                          ) {
                            fielderror.email =
                              "Email length should be 10 to 40";
                          } else if (
                            !e.target.value.endsWith(".com") &&
                            !e.target.value.endsWith(".in")
                          ) {
                            fielderror.email = "Enter valid email";
                          } else {
                            fielderror.email = "";
                            debounceEmail(e.target.value);
                          }
                          setStrategyData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }));
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>{fielderror.email}</span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Strategy Name
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Strategy Name"
                        value={
                          strategyData?.strategyname
                            ? strategyData?.strategyname
                            : ""
                        }
                        onChange={(e) => handleStrategyName(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.strategyname}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Strategy Category
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          value={strategyData?.strategycategory?.toUpperCase()}
                          onChange={strategyCategoryType}
                          className="dropdown"
                        >
                          {strategyCategoryOption.map((ele, index) => (
                            <MenuItem key={index} value={ele?.toUpperCase()}>
                              {ele?.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {fielderror && (
                      <span style={{ color: "red" }}>
                        {fielderror.strategycategory}
                      </span>
                    )}
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Segment
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          multiple
                          value={strategyData?.segment}
                          onChange={handleSegmentChange}
                          renderValue={(selected) => selected.join(", ")}
                          className="dropdown"
                          placeholder="Select Segment"
                        >
                          {segmentOption.map((ele) => (
                            <MenuItem
                              key={ele}
                              value={ele}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px 15px",
                                gap: "6px",
                              }}
                            >
                              <Checkbox
                                sx={{
                                  transform: "scale(1.3)",
                                  padding: 0,
                                }}
                                checked={selectedValues.indexOf(ele) > -1}
                              />
                              <ListItemText
                                primary={ele}
                                primaryTypographyProps={{
                                  fontSize: "14px",
                                }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {fielderror && (
                      <span style={{ color: "red" }}>{fielderror.segment}</span>
                    )}
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Timeframe
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          value={strategyData?.timeframe?.toUpperCase()}
                          onChange={timeframeDrp}
                          className="dropdown"
                        >
                          {timeframeOption.map((ele) => (
                            <MenuItem key={ele} value={ele}>
                              {ele}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {fielderror && (
                      <span style={{ color: "red" }}>
                        {fielderror.timeframe}
                      </span>
                    )}
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Trade Per Day
                      </Typography>

                      <FormControl
                        className="dropdown-ta"
                        sx={{ marginRight: 1 }}
                      >
                        <Select
                          value={strategyData?.tradeperday}
                          onChange={tradeperdayDrp}
                          className="dropdown"
                        >
                          {tradeperdayOption.map((ele) => (
                            <MenuItem key={ele} value={ele}>
                              {ele}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {fielderror && (
                      <span style={{ color: "red" }}>
                        {fielderror.tradeperday}
                      </span>
                    )}
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Indicators Name with Value
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Indicators Name with Value"
                        value={
                          strategyData?.indicators
                            ? strategyData?.indicators
                            : ""
                        }
                        onChange={(e) => handleIndicators(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.indicators}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Stop Loss
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Stop Loss"
                        value={
                          strategyData?.stoploss ? strategyData?.stoploss : ""
                        }
                        onChange={(e) => handleStopLoss(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.stoploss}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Target
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Target"
                        value={strategyData?.target ? strategyData?.target : ""}
                        onChange={(e) => handleTarget(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.target}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography component={"label"} className="label">
                        Additional Notes
                      </Typography>
                      <InputBase
                        type="text"
                        placeholder="Enter Additional Notes"
                        value={
                          strategyData?.additionalnotes
                            ? strategyData?.additionalnotes
                            : ""
                        }
                        onChange={(e) => handleAdditionalNotes(e)}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.additionalnotes}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Entry Conditions
                      </Typography>
                      <TextField
                        value={
                          strategyData?.entryconditions
                            ? strategyData?.entryconditions
                            : ""
                        }
                        onChange={(e) => handleEntryConditions(e)}
                        fullWidth
                        label=""
                        multiline
                        rows={3}
                        placeholder="Enter Entry Conditions"
                        InputProps={{
                          sx: {
                            padding: 0,
                          },
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.entryconditions}
                        </span>
                      )}
                    </Box>
                  </Box>
                  <Box className="affiliate_item">
                    <Box className="form_control mrt-20">
                      <Typography
                        component={"label"}
                        className="label required"
                      >
                        Exit Conditions
                      </Typography>
                      <TextField
                        value={
                          strategyData?.exitconditions
                            ? strategyData?.exitconditions
                            : ""
                        }
                        onChange={(e) => handleExitConditions(e)}
                        fullWidth
                        label=""
                        multiline
                        rows={3}
                        placeholder="Enter Exit Conditions"
                        InputProps={{
                          sx: {
                            padding: 0,
                          },
                        }}
                      />
                      {fielderror && (
                        <span style={{ color: "red" }}>
                          {fielderror.exitconditions}
                        </span>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Tooltip
                  title={isSubmit ? submitTooltip : ""}
                  arrow
                  placement="right"
                >
                  <div style={{ width: "max-content" }}>
                    <Box
                      className="form_control checkBox mrt-20"
                      style={{ display: "flex" }}
                    >
                      <Button
                        className="btn-ta filled"
                        onClick={handleFormSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                </Tooltip>
              </Box>

              <Box className="benefit border-gradient">
                <Typography className="charges-style" component={"p"}>
                  <Typography
                    component="p"
                    className="span-style charges-style"
                  >
                    Disclaimer for Strategy Creation Request:
                  </Typography>
                  The strategy creation request submitted to DeMatade Algo
                  Technology Solutions Private Limited is subject to review and
                  approval. The company does not guarantee the execution,
                  profitability, or accuracy of any created strategy. All
                  strategies are developed based on the provided inputs and
                  market conditions, which may change over time. Users are
                  advised to conduct their own due diligence before making any
                  investment decisions. DeMatade Algo Technology Solutions
                  Private Limited shall not be liable for any financial losses
                  or risks arising from the use of the developed strategies.
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Dialog scroll={scroll} open={isLoading} className="dialogBox">
        <DialogContent
          sx={{ padding: "0px", minHeight: "fit-content", overflow: "hidden" }}
        >
          <Box className="planCard premium">
            <Box
              className="planCard-type"
              style={{
                height: "100%",
                justifyContent: "start",
                marginTop: "35px",
                height: "100%",
              }}
            >
              <Box className="logo_wrapper">
                <img src={logo} />
              </Box>
              {isPartnerSuccessfullyCreated ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "60px",
                  }}
                >
                  <div
                    style={{
                      background: "#28b128",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      class=" payment_icon fa-solid fa-check"
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </div>
                  <p
                    style={{
                      fontSize: "1.5em",
                      marginTop: "10px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Strategy Creation Request Sent Successfully.
                  </p>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box className="logo_div">
                    <div class="lds-dual-ring img"></div>
                  </Box>

                  <Typography
                    sx={{
                      marginTop: 3,
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    className="loading_text payment_title"
                    component={"p"}
                  >
                    Please wait while we process your request.
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "40px !important",
                      fontWeight: 600,
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    className="loading_text payment_title"
                    component={"p"}
                  >
                    PLEASE DO NOT REFRESH THE PAGE
                    <p
                      style={{
                        marginTop: "14px",
                        fontSize: "1.5em",
                        fontWeight: 600,
                      }}
                    >
                      00:{formatTime(seconds)}
                    </p>
                  </Typography>
                </Box>
              )}
            </Box>
            {isPartnerSuccessfullyCreated && (
              <Button
                className={"btn-ta filled"}
                onClick={() => setIsLoading(false)}
              >
                Done
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
