import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import privacypolicy from "../images/privacy-policy.png";

export default function Faq() {
  return (
    <>
      <Box component={"section"} className="hero_section hero_section_small">
        <img src={privacypolicy} />
        <Box className="hero_content">
          <Box className="container">
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} xl={12}>
                <Typography component={"h2"}>
                  Frequently asked Questions
                </Typography>
                <Typography component={"p"}>
                  Have questions? We’re here to help.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box className="container">
        <Box component={"section"}>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title">
              Algo Trading
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              What is Algo Trading?
            </Typography>
            <Typography component={"p"} className="common_text">
              Algorithmic trading, often known as black box trading, follows a
              predefined set of trading instructions from a computer programme.
              It makes earnings at a rate and frequency that a human trader
              cannot match. The rules are established in accordance with the
              amount, time, pricing, or mathematical model. Algorithmic trading
              gives liquidity to markets and profits to traders, as well as a
              methodical approach to trading free of human emotions. Simply
              said, algorithmic trading eliminates any manual intervention.
              After coding the logic rules in the algorithm, it will do
              point-to-point automation. It follows the market, makes orders
              when an opportunity arises, and monitors risk and stop loss. When
              necessary, it also squares off the trade. Algorithms can assist in
              trading F&O, equities, FOREX, cryptocurrencies such as BITFINEX
              and BITMEX, and commodities on Indian exchanges such as the BSE,
              NSE, NCDEX, MCX, and others.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              How Algo Trading Works?
            </Typography>
            <Typography component={"p"} className="common_text">
              Algorithmic trading makes trade entries and exits based on the set
              parameters, such as volatility levels or price changes, utilizing
              chart analysis and algorithms. Once they locate favorable market
              circumstances in accordance with the specified criteria, trading
              algorithms are able to submit orders for buying or selling on
              behalf of the user.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              {" "}
              How is algo trading better than manual trading?
            </Typography>
            <Typography component={"p"} className="common_text">
              Two of the key benefits of algo trading over human trading are
              speed and accuracy. Pre-defined and well executed algorithms. It
              completes transactions in a split second. Human traders are unable
              to deal at this speed. It runs and scans on a number of factors
              that are nearly hard for a person to handle. Therefore, there are
              more solutions available at lower costs. On the other hand, there
              is no chance of making mistakes by selecting the incorrect deal.
              If you choose the manual option, there is a potential that you may
              invest in the incorrect currency pair or trade for the incorrect
              amount. A cross check of an algorithm has already been performed
              to confirm the correct sequence. Additionally, algorithmic trading
              eliminates human emotions that produce irrational conclusions. The
              most frequent offenders that influence the human mind are greed
              and fear.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              What are the pros and cons of Algo Trading?
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Is Free API Key available?
            </Typography>
            <Typography component={"p"} className="common_text">
              Dematade currently has affiliateships with brokers, therefore
              there is no requirement for an API key; clients simply need to
              sign up using their credentials for a broker account.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Does algo trading need constant monitoring over laptop or PC?
            </Typography>
            <Typography component={"p"} className="common_text">
              Yes, the trader must keep an eye on the trading session to prevent
              any technical problems like connection and power outages. The
              execution of the strategy has to be constantly monitored.
              Additionally, make sure that algorithms don't include any
              incorrect, redundant, or missing orders.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              What are the system requirements?
            </Typography>
            <Typography component={"p"} className="common_text">
              All you need is 4GB of RAM and reliable internet access.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              What about the signal accuracy?
            </Typography>
            <Typography component={"p"} className="common_text">
              All of the algorithms used to generate the buy/sell signals have
              undergone extensive back testing as well as several iterations in
              the live market, leading to a high success rate.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Is Algo Trading legal in India?
            </Typography>
            <Typography component={"p"} className="common_text">
              In 2008, the Securities and Exchange Board of India (SEBI) passed
              a regulation for institutional investors for the legality of
              automated trading in India. So, a short answer is “Yes”, it is
              legal in India. In fact, over 43% of NSE trades done today are
              algorithmic ones.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Do you guarantee profits with it?
            </Typography>
            <Typography component={"p"} className="common_text">
              No, you can only predict profits based on the algorithm that has
              been configured. We make no promises regarding a company's
              financial success.
            </Typography>
          </Box>
        </Box>

        <Box component={"section"}>
          <Box className="mrt-20">
            <Typography component={"h3"} className="sec_title">
              Demat Account
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              {" "}
              Can I open multiple DEMAT accounts?
            </Typography>
            <Typography component={"p"} className="common_text">
              Yes, you can lawfully have many Demat accounts, just as you may
              have multiple trading accounts. The only restriction is that you
              cannot have both with the same DP or broker. If you create a Demat
              account with us, you will receive one month of free Algo software
              subscription.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Can I open a DEMAT account for securities in multiple ownership
              patterns?
            </Typography>
            <Typography component={"p"} className="common_text">
              No, for multiple ownership patterns you need to establish a new
              account. You must register two distinct DEMAT accounts, for
              instance, if you hold share certificates in your name, your
              spouse's name, and another name.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Do I need to furnish my account details while opening the DEMAT
              account?
            </Typography>
            <Typography component={"p"} className="common_text">
              To safeguard the interests of the customer, it is necessary. Your
              bank account number will be shown on your dividend or interest
              warrant. This prevents anyone other than you from using these
              warrants.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Can I assign a power of attorney to someone else to operate my
              account?
            </Typography>
            <Typography component={"p"} className="common_text">
              Yes, you can choose someone to manage your account on your behalf.
              You must execute a power of attorney and provide it to the DP.
            </Typography>
          </Box>
          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Can I change my account details?
            </Typography>
            <Typography component={"p"} className="common_text">
              Yes, you can alter the account information, but you must maintain
              the DP since all cash rewards will be disbursed to your bank
              account in accordance with the stipulated points.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Who is eligible for the nomination facility?
            </Typography>
            <Typography component={"p"} className="common_text">
              Nominations can only be made by persons who hold beneficiary
              accounts, either individually or jointly. Nominations may not be
              made on behalf of any trust, society, corporate organisation,
              Karta of HUDs, affiliateship company, power of attorney holder, or
              other non-individual entity.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              {" "}
              Is it possible to have multiple nominees?
            </Typography>
            <Typography component={"p"} className="common_text">
              No, each depository account may only have one nominee.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Are minors eligible to nominate?
            </Typography>
            <Typography component={"p"} className="common_text">
              No, minors do not have the authority to designate on their own or
              with a guardian.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Does an NRI have a right to nominate?
            </Typography>
            <Typography component={"p"} className="common_text">
              Yes, a nominee who is an NRI is allowed to run alone. However,
              they are unable to designate anybody with a power of attorney.
            </Typography>
          </Box>

          <Box className="mrt-20">
            <Typography
              component={"h3"}
              className="sec_title sec_title_small notAfter star"
            >
              Is it possible to freeze a depository account?
            </Typography>
            <Typography component={"p"} className="common_text">
              You can freeze your account for any debts as well as credits.
              Until the account is unfrozen, no debits will be permitted from
              the one you have frozen for debits.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
