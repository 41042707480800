import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import authReducers from "../redux/reducers/authReducer";
import affiliateReducer from "../redux/reducers/affiliateReducer";

const rootReducer = combineReducers({
  Auth: authReducers,
  affiliate: affiliateReducer,
});

const logger = (store) => (next) => (action) => {
  console.group(action.type);
  let result = next(action);
  console.groupEnd();
  return result;
};

const monitorReducerEnhancer =
  (createStore) => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
      const start = performance.now();
      const newState = reducer(state, action);
      const end = performance.now();
      const diff = Math.round(end - start);

      return newState;
    };

    return createStore(monitoredReducer, initialState, enhancer);
  };

const intialState = {};

const middlewares = [logger, thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

const store = createStore(rootReducer, intialState, composedEnhancers);

export default store;
