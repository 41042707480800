import { Box, InputBase, Typography } from '@mui/material';
import React from 'react';

const InputWithError = (props) => {
	const {
		label,
		value,
		onChange,
		error,
		placeholder,
		type = 'text',
		...otherProps
	} = props;

	return (
		<Box className='form_control'>
			{label && (
				<Typography component={'label'} className='label '>
					{label}
				</Typography>
			)}
			<InputBase
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				{...otherProps}
			/>
			{error && <p style={{ marginTop: '5px', color: 'red' }}>{error}</p>}
		</Box>
	);
};

InputWithError.propTypes = {};

export default InputWithError;
