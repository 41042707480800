export const validateStrategy = (fields) => {
  let isValid = {};

  if (!fields.strategyname) {
    isValid.strategyname = "Enter Strategy name";
  }

  if (!fields?.strategycategory || fields?.strategycategory === "") {
    isValid.strategycategory = "Strategy Category is required.";
  }

  if (
    !fields?.segment ||
    (Array.isArray(fields.segment) && fields.segment.length === 0)
  ) {
    isValid.segment = "Segment is required.";
  }

  if (!fields?.timeframe || fields?.timeframe === "") {
    isValid.timeframe = "Time Frame is required.";
  }

  if (!fields?.tradeperday || fields?.tradeperday === "") {
    isValid.tradeperday = "Trade per Day is required.";
  }

  if (!fields.indicators) {
    isValid.indicators = "Indicators is required.";
  } else if (fields.indicators.length >= 100 || fields.indicators.length <= 5) {
    isValid.indicators = "Indicators length should be 5-100";
  }

  if (!fields.entryconditions) {
    isValid.entryconditions = "Entry Conditions is required.";
  } else if (
    fields.entryconditions.length >= 200 ||
    fields.entryconditions.length <= 10
  ) {
    isValid.entryconditions = "Entry Conditions length should be 10-200";
  }

  if (!fields.exitconditions) {
    isValid.exitconditions = "Exit Conditions is required.";
  } else if (
    fields.exitconditions.length >= 200 ||
    fields.exitconditions.length <= 10
  ) {
    isValid.exitconditions = "Exit Conditions length should be 10-200";
  }

  if (!fields.stoploss) {
    isValid.stoploss = "Stop Loss is required.";
  }

  if (!fields.target) {
    isValid.target = "Target is required.";
  }

  if (!fields.ownername) {
    isValid.ownername = "Owner Name is required.";
  }

  if (!fields.whatsappnumber) {
    isValid.whatsappnumber = "Phone Number is required.";
  } else if (!/^[0-9]+$/.test(fields?.whatsappnumber?.replace(/-/g, ""))) {
    isValid.whatsappnumber = "Phone Number should be numeric value.";
  } else if (fields?.whatsappnumber?.replace(/-/g, "").length < 10) {
    isValid.whatsappnumber = "Enter valid phone number ";
  }

  if (!fields.email) {
    isValid.email = "Please enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields.email)) {
    isValid.email = "Invalid email address";
  } else if (!(fields.email.length >= 10) || !(fields.email.length <= 40)) {
    isValid.email = "Email length should be 10 to 40";
  } else if (!fields.email.endsWith(".com") && !fields.email.endsWith(".in")) {
    isValid.email = "Enter valid email";
  }

  return isValid;
};
