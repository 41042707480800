import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import privacypolicy from '../images/privacy-policy.png';

export default function Descliamer() {
  return (
    <>
      <Box component={'section'} className='hero_section hero_section_small'>
        <img src={privacypolicy} />

        <Box className='hero_content'>
          <Box className='container'>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} xl={5}>
                <Typography component={'h2'}>Disclaimer</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className='container'>
        <Box component={'section'} >
          <Box className='mrt-20'>
            <Typography component={'p'} className='common_text'>Please note that the information provided by Trade Arth is for educational purposes only. Trade Arth staff does not provide specific investment advice, advocate for any particular investment methodology, endorse specific financial tools or trading platforms, or recommend any particular financial planner, advisor, or broker-dealer.Trading and investing always involve high levels of risk, and any decisions you make regarding your trading or investment activities are your sole responsibility. Trade Arth does not take any responsibility for any trading or investing activity you undertake.Subscription fees paid to Trade Arth are non-refundable, and their service is limited to automated trading application development, deployment, and maintenance.All algorithms provided by Trade Arth are based on backtested data, but there is no guarantee of their future performance. Past performance is not indicative of future results. Any algorithm running in an automated system is agreed upon with the user before deployment, and Trade Arth does not accept liability for any losses generated by the use of these algorithms.It is essential to understand that trading and investing in financial markets carry inherent risks, and you should carefully consider your financial situation, risk tolerance, and investment goals before making any decisions. If you require personalized financial advice, it is recommended to consult a qualified financial advisor or professional.</Typography>
          </Box>

        </Box>
      </Box>
    </>
  )
}
